import React, { useState } from "react"

export default function useForm(initState = {}) {
    const [state, setState] = useState(initState)

    const handleChange = e => {
        e.persist()
        setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    return { state, handleChange, setState }
}
